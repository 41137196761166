.ui.grid>.row {
  padding-bottom: 0;
}

.ui.label.emblem-valid > .icon {
  margin: 0;
}

.ui.grid > .row.game-actions-wrapper {
  padding: 1em 0 0.5em 0;
}

.player.player-dead {
  color: #B03060;
}

.ui.bulleted.list.event-list {
  margin-top: 10px;
}

.menu-title {
  margin-right: 5px;
}

.group-players-card__content {
  display: flex;
  justify-content: space-between;
}

.ui.card.group-players-card > .content.group-players-card__content::after {
  content: none;
}

.list-group-players {
  font-size: smaller;
}

.list-roles {
  display: flex;
  flex-wrap: wrap;
}

.list-roles > .label {
  margin-right: 5px;
  margin-bottom: 5px;
}

.input-numeric {
  display: flex;
}

.input-numeric__input {
  margin-left: auto;
}

.input-numeric__input > .ui.button {
  margin: 0;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 0;
}

.input-numeric__input > .ui.label {
  margin: 0;
  padding: 12px 0.8em;
  border-radius: 0;
}

.new-setup > * {
  margin-bottom: 10px;
}

.ui.list > .item.setup-list-item {
  display: flex;
}

.setup-list-item > .ui.button {
  margin-left: auto;
}

.ui.button.win-action-button {
  margin-bottom: 5px;
}

.ui.button.win-action-button:first-child {
  margin-top: 5px;
}
